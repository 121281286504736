<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader
            :title="headercontent.title"
            :addlink="headercontent.addlink"
          />
        </v-col>
        <v-col md="12" v-if="loadingPage">
          <v-skeleton-loader
            class="mx-auto"
            type="table"
            :loading="loadingPage"
            rows="5"
          ></v-skeleton-loader>
        </v-col>
        <v-col md="12" v-if="!loadingPage">
          <v-simple-table class="table" v-if="!allLoadingCurd">
            <template v-slot:default v-if="allCategory.results">
              <thead>
                <tr>
                  <th class="text-left">S/N</th>
                  <th class="text-left">Name</th>
                  <th class="text-right" colspan="2">Edit</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in allCategory.results.list"
                  :key="index"
                >
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.title }}</td>
                  <td>
                    <img
                      :src="'https://app.necojobs.com.np' + item.imageUrl"
                      alt=""
                    />
                  </td>
                  <td class="text-right">
                    <router-link :to="`/dashboard/category/add/${item.slug}`">
                      <v-icon class="icons-action" v-if="true">
                        mdi-pencil
                      </v-icon></router-link
                    >
                    <v-icon
                      class="icons-action"
                      @click="() => deleteCategoryAct(item._id)"
                    >
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <div v-if="allLoadingCurd">
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
            <v-skeleton-loader type="image" height="200"></v-skeleton-loader>
          </div>
          <ul
            class="pagination-button"
            v-if="allCategory.results != undefined && !allLoadingCurd"
          >
            <li>
              Page :
              <span v-if="allCategory.results.pagination.next">{{
                (allCategory.results.pagination.next - 1) * this.limit
              }}</span
              ><span v-if="!allCategory.results.pagination.next">{{
                allCategory.results.pagination.total
              }}</span>
              -
              {{ allCategory.results.pagination.total }}
            </li>
            <li>
              <div>
                <v-btn
                  v-if="allCategory.results.pagination.previous"
                  @click="prevPage(allCategory.results.pagination.previous)"
                  class="btn-dark"
                  >Prev</v-btn
                >

                <v-btn
                  v-if="allCategory.results.pagination.next"
                  @click="nextPage(allCategory.results.pagination.next)"
                  class="btn-dark"
                  >Next</v-btn
                >
              </div>
            </li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Category",
  components: { PageHeader },
  data: () => ({
    headercontent: {
      title: "Categories",
      addlink: "/dashboard/category/add",
    },
    title: "",
    adsposition: "",
    valid: true,
    limit: 100,
    skip: 1,
    id: undefined,
    loadingPage: true,
    loading: true,
  }),
  methods: {
    ...mapActions(["fetchCategory", "deleteCategory"]),
    validate() {},
    nextPage(data) {
      this.fetchCategory({
        limit: this.limit,
        skip: data,
        categoryType: "job",
      });
    },
    prevPage(data) {
      this.fetchCategory({
        limit: this.limit,
        skip: data,
        categoryType: "job",
      });
    },

    async deleteCategoryAct(id) {
      if (confirm("Are you sure you want to delete this category?")) {
        this.loading = true;
        this.id = id;
        await this.deleteCategory(id);
        await this.fetchCategory({
          limit: this.limit,
          skip: this.skip,
          categoryType: "job",
        });
        this.loading = false;
        this.id = undefined;
      }
    },
  },
  async created() {
    this.fetchCategory({
      limit: this.limit,
      skip: this.skip,
      categoryType: "job",
    });
    this.loadingPage = false;
  },
  computed: {
    ...mapGetters(["allCategory", "allLoadingCurd"]),
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.form-section {
  padding: 15px 0;
  .col {
    padding: 0 10px;
  }
}
.filtertitle {
  margin-bottom: 10px;
}
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  margin-top: -10px;
}

.table {
  box-shadow: 0px -1px 5px 2px rgba(0, 0, 0, 0.1);

  thead {
    border-bottom: 2px solid $menucolor;
    background: $tagcolor;
  }
  th {
    font-size: $normal_lfont !important;
    color: $white !important;
  }
  td {
    img {
      width: 100px;
      padding: 20px;
    }
  }
}
.pagination-button {
  display: flex;
  list-style: none;
  padding-left: 0;
  justify-content: space-between;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  .btn-dark {
    margin-top: 0;
    margin-left: 10px;
  }
}
.table-heading,
.pagination-button {
  border: 1px solid $menucolor;
  margin-bottom: 0;
  padding: 10px;
  align-items: center;
}
.icons-action {
  margin: 10px;
  font-size: $iconfont;
}
</style>
